import React from "react"

import PropTypes from "prop-types"
import "../css/content.css"
import "../css/background-image.css"


const Layout = ({ children }) => {
  return (
    <>
      <main>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
